import { CPHeaderLinkWrapper } from '@codeparva/gatsby-theme-layout-components';
import { FaLandmark } from '@react-icons/all-files/fa/FaLandmark';
import { Link } from 'gatsby';

export const topbardata: any = {
  hideOnMobileDevice: true, // hide topbar on mobile device
  closeButton: 'FaWindowClose', // close button for announcement bar
  showAnnouncementBar: true,
  locationType: 'single', // single or multi - single will show the phone and location icon in the navbar burger menu
  ctaText: 'Give Us a Call Today',
  locations: {
    location: {
      id: '1',
      socialIcons: [
        // {
        //   iconName: 'FaTwitter',
        //   iconsUrl: 'https://twitter.com/CatskillSS',
        // },
        {
          iconName: 'FaFacebook',
          iconsUrl: 'https://www.facebook.com/CapeMarina/',
        },
        // {
        //   iconName: 'FaLinkedin',
        //   iconsUrl: '/',
        // },
      ],
      contactDetails: {
        mailingAddress: '790 Mullet Rd, Cape Canaveral FL 32920',
        emailID: 'dockmaster@capemarina.com',
        phoneNumber: '321-783-8410',
        facilityDetails: {
          facilityAddress: '790 Mullet Rd, Cape Canaveral FL 32920',
          googleMapsUrl: 'https://goo.gl/maps/M3q1BkM2gybTPvJt7',
          facilityName: 'Cape Marina Storage',
        },
      },
    },
  },
  syrasoftEmailID: 'syrasoftsoftware@gmail.com',
  iconsConfig: {
    phoneNumberIcon: 'FaPhoneAlt',
    emailIDIcon: 'FaEnvelope',
    facilityAddressIcon: 'FaMapMarkerAlt',
    mailingAddressIcon: 'FaEnvelope',
  },
  variant: 'topbar-1',
  theme: 'dark',
  buttonsConfig: {
    text: 'Pay Rent',
    iconName: 'FaDollarSign',
    show: false,
    showOnTouchDevice: true,
    url: '/account/login',
  },
};

export const navbardata: any = {
  locations: {
    location: {
      id: '1',
      socialIcons: [
        // {
        //   iconName: 'FaTwitter',
        //   iconsUrl: 'https://twitter.com/CatskillSS',
        // },
        // {
        //   iconName: 'FaFacebook',
        //   iconsUrl: 'https://www.facebook.com/catskillselfstorage',
        // },
        // // {
        //   iconName: 'FaLinkedin',
        //   iconsUrl: '/',
        // },
      ],
      facilityDetails: {
        facilityName: 'Cape Marina Storage',
        facilityAddress: '790 Mullet Rd, Cape Canaveral FL 32920',
        googleMapsUrl: 'https://goo.gl/maps/M3q1BkM2gybTPvJt7',
        facilityLogo: {
          logo: `https://tfw-v11-media-staging.s3.ap-south-1.amazonaws.com/Logo_Plus_Clean_Marina_New_cbd391dc15.png`,
          renderAs: 'figure',
          altText: 'a logo provided',
        },
      },
      phoneNumber: '321-783-8410',
    },
  },

  iconsConfig: {
    phoneNumberIcon: 'FaPhoneAlt',
    facilityAddressIcon: 'FaMapMarkerAlt',
  },
  GatsbyImage: FaLandmark,
  locationType: topbardata.locationType,
  LinkWrapper: Link,
  navLinks: [
    // {
    //   NavLinkText: 'Home',
    //   isNavLinkDropDown: false,
    //   isGrouped: false,
    //   linkTo: '/',
    //   RenderAs: CPHeaderLinkWrapper,
    //   newTab: true,
    //   navLinkDropDown: [],
    // },
    // {
    //   // this element can be skipped if the locationType is "single"
    //   NavLinkText: 'View Rates',
    //   isNavLinkDropDown: false,
    //   isGrouped: false,
    //   linkTo: '/new-york/catskill/view-rates',
    //   RenderAs: CPHeaderLinkWrapper,
    //   newTab: true,
    //   navLinkDropDown: [],
    // },
    {
      NavLinkText: 'Pay Rent',
      isNavLinkDropDown: false,
      isGrouped: false,
      linkTo: '/account/login',
      RenderAs: CPHeaderLinkWrapper,
      newTab: true,
      navLinkDropDown: [],
    },
    // {
    //   NavLinkText: 'Storage Tips',
    //   isNavLinkDropDown: false,
    //   isGrouped: false,
    //   linkTo: '/storage-tips',
    //   RenderAs: CPHeaderLinkWrapper,
    //   newTab: true,
    //   navLinkDropDown: [],
    // },
    // {
    //   NavLinkText: 'FAQ',
    //   isNavLinkDropDown: false,
    //   isGrouped: false,
    //   linkTo: '/faq',
    //   RenderAs: CPHeaderLinkWrapper,
    //   newTab: true,
    //   navLinkDropDown: [],
    // },
    // {
    //   NavLinkText: 'Contact Us',
    //   isNavLinkDropDown: false,
    //   linkTo: '/new-york/catskill/contact',
    //   RenderAs: CPHeaderLinkWrapper,
    //   newTab: true,
    //   navLinkDropDown: [],
    // },
  ],
  buttonsConfig: [],
};
