import { LocationTypes } from '@codeparva/gatsby-theme-layout-components';
import { FaMapMarker } from '@react-icons/all-files/fa/FaMapMarker';

export const exampleLocations: LocationTypes.TLocation[] = [
  {
    addressText: '',
    googleMapPlaceId: 'ChIJFXeGt-NXBogR0vO5X6-E3VM',
    useGoogleMapsContactDetails: true,
    contactNumber: '321-783-8410',
    emailAddress: 'dockmaster@capemarina.com',
    facilityName: 'Catskill Self Storage',
    thumbnail: `${process.env.GATSBY_S3_BASE_URL}/storage-warehouse-1553550_960_720.jpg`,
    hours: {
      officeHrs: {
        weekdays: {
          open: '0900',
          close: '1800',
        },
        weekends: {
          open: '0900',
          close: '1500',
        },
      },
      gateHrs: {
        allDays: {
          open: '24 hours',
          close: 'NA',
        },
        // weekdays: {
        //   open: '0930',
        //   close: '2000',
        // },
        // weekends: {
        //   open: '0900',
        //   close: '1200',
        // },
        // allDays: {
        //   open: '0930',
        //   close: '2000',
        // },
      },
    },
    unitStartingPrice: 80,
  },
  // {
  //   addressText: '14 Deer Ln, Catskill, NY 12414, USA',
  //   contactNumber: '3217838410',
  //   useGoogleMapsContactDetails: true,
  //   emailAddress: 'location1@location.com',
  //   googleMapPlaceId: 'ChIJX4pZbTy83YkRF-9d-vBYW_Q',
  //   facilityName: 'Barnwood',
  //   thumbnail: `${process.env.GATSBY_S3_BASE_URL}/Self_storage_indoor.jpg`,
  //   hours: {
  //     officeHrs: {
  //       weekdays: {
  //         open: '0900',
  //         close: '1830',
  //       },
  //       weekends: {
  //         open: '0900',
  //         close: '1200',
  //       },
  //     },
  //     gateHrs: {
  //       weekdays: {
  //         open: '0930',
  //         close: '2000',
  //       },
  //       weekends: {
  //         open: '0900',
  //         close: '1200',
  //       },
  //     },
  //   },
  //   unitStartingPrice: 80,
  // },
  // {
  //   addressText: '14 Deer Ln, Catskill, NY 12414, USA',
  //   contactNumber: '3217838410',
  //   useGoogleMapsContactDetails: true,
  //   emailAddress: 'location1@location.com',
  //   googleMapPlaceId: 'ChIJX4pZbTy83YkRF-9d-vBYW_Q',
  //   facilityName: 'Barnwood',
  //   thumbnail: `${process.env.GATSBY_S3_BASE_URL}/Self_storage_indoor.jpg`,
  //   hours: {
  //     officeHrs: {
  //       weekdays: {
  //         open: '0900',
  //         close: '1830',
  //       },
  //       weekends: {
  //         open: '0900',
  //         close: '1200',
  //       },
  //     },
  //     gateHrs: {
  //       weekdays: {
  //         open: '0930',
  //         close: '2000',
  //       },
  //       weekends: {
  //         open: '0900',
  //         close: '1200',
  //       },
  //     },
  //   },
  //   unitStartingPrice: 80,
  // },
];

export const locationMapConfig: LocationTypes.ILocationMap = {
  googleMapAPIKey: 'AIzaSyBNBMPituvbChRZKqK3LSgkNVCANM8X47U',
  // height: 750,
  infoWindowmode: 'default',
  locations: exampleLocations,
  showNearbyBusiness: false,
  // variant: 'map-multi-locations',
  variant: 'map-multi-1',
  // variant: 'cover-image',
  fullWidth: true,
  // width: 800,
  zoomLevel: 15,
  pinIcon: FaMapMarker,
  defaultCoordinates: {
    lat: 43.20263449999999,
    lng: -89.32374866970848,
  },
  useIframe: true,
};
