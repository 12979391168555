/* eslint-disable @typescript-eslint/no-explicit-any */
import { FooterTypes } from '@codeparva/gatsby-theme-layout-components';
import { getDynamicUrl, getUrl } from '@codeparva/gatsby-theme-tfw-2/src/utils/urlUtility';
import { generateNavLinks } from '../Header/transformer';
import { getTransformedHours } from '@codeparva/gatsby-theme-tfw-2/src/components/homePageJumbotronDemo/transformer';
import { exampleLocations } from '../locationMap/config';
import { footerConfig } from './config';

const generateTermsAndPrivacyDisclaimer = (otherLinks: any) => {
  return otherLinks.map((link: any) => {
    return {
      linkName: link.link_label,
      linkUrl: link.link_url,
      newTab: true,
    };
  });
};

const transformedFooterButtonConfig = (
  strapiFooterConfig: any,
  location_city: any,
  location_state: any,
  locationDetails: any,
) => {
  const { footer_cta_buttons } = strapiFooterConfig;
  if (footer_cta_buttons) {
    return footer_cta_buttons.map((button: any) => {
      const { url, label } = button;
      const updatedUrl = getDynamicUrl(url.replace(/_/g, '-'), location_state, location_city);
      return {
        label,
        url: getUrl(locationDetails, updatedUrl),
        icon: {
          name: 'FaDollarSign',
          position: 'right',
          type: 'arrow',
          size: 16,
        },
      };
    });
  } else {
    return footerConfig.buttonsConfig;
  }
};
const getSocialIcons = (socialMediaLinks: any) => {
  const { facebook_url, instagaram_url, linkedin_url, twitter_url } = socialMediaLinks;
  const newSocialIcons: FooterTypes.TIconsType[] = [];
  if (facebook_url) {
    newSocialIcons.push({
      iconName: 'FaFacebook',
      socialIcon: 'tfw-facebook-2-cp',
      iconsUrl: facebook_url,
    });
  }
  if (twitter_url) {
    newSocialIcons.push({
      iconName: 'FaTwitter',
      socialIcon: 'tfw-twitter-2-cp',
      iconsUrl: twitter_url,
    });
  }
  if (instagaram_url) {
    newSocialIcons.push({
      iconName: 'FaInstagram',
      iconsUrl: instagaram_url,
    });
  }
  if (linkedin_url) {
    newSocialIcons.push({
      iconName: 'FaLinkedin',
      iconsUrl: linkedin_url,
    });
  }
  return newSocialIcons;
};
const transformLocationDetailsFooter = (
  locationDetails: any,
  facilityDetails: any,
  emailID: any,
) => {
  const {
    attributes: {
      location_contact_details: {
        location_social_media_links,
        location_facility_address,
        location_google_maps_url,
        location_phone_number,
        location_mailing_address,
        location_email_address,
      },
      location_name,
      // location_state,
      // location_city,
    },
  } = locationDetails;
  // facility_logo.data.attributes.alternativeText
  // const {
  //   facility_logo: {
  //     data: {
  //       attributes: { alternativeText, url },
  //     },
  //   },
  // } = facilityDetails;
  const { facility_logo } = facilityDetails;
  const facilityLogoUrl = facility_logo?.data?.attributes?.url || '';
  const facilityLogoAltText = facility_logo?.data?.attributes?.alternativeText || '';
  const updatedLocation: FooterTypes.TLocation = {
    id: locationDetails.id,
    // Adding if check for social icons
    ...(location_social_media_links && {
      socialIcons: getSocialIcons(location_social_media_links),
    }),
    contactDetails: {
      syrasoftEmailID: emailID || footerConfig.syrasoftEmailID,
      mailingAddress: location_mailing_address || location_facility_address,
      emailID: location_email_address,
      phoneNumber: location_phone_number,
      facilityDetails: {
        facilityAddress: location_facility_address,
        googleMapsUrl: location_google_maps_url,
        facilityName: location_name,
        facilityLogo: {
          logo: facilityLogoUrl,
          altText: facilityLogoAltText,
          renderAs: 'figure',
        },
      },
    },
  };
  return updatedLocation;
};
export const transformedFooterConfig = (
  footerData: FooterTypes.IFooterConfig,
  strapiFooterConfig: any,
  strapiTopBarConfig: any,
  locationDetails: any,
  facilityDetails: any,
) => {
  if (strapiFooterConfig) {
    const {
      footer_theme: { section_theme },
      footer_variant,
      other_links,
    } = strapiFooterConfig;
    const defaultFooterDataCopy = { ...footerData };
    defaultFooterDataCopy.theme = section_theme;
    defaultFooterDataCopy.variant = footer_variant.replace(/_/g, '-');
    if (locationDetails && locationDetails.length > 0) {
      defaultFooterDataCopy.locationType = locationDetails.length > 1 ? 'multi' : 'single';
      defaultFooterDataCopy.locations = {
        location: transformLocationDetailsFooter(
          locationDetails[0],
          facilityDetails,
          strapiTopBarConfig?.syrasoft_email_id || '',
        ),
      };
    }
    const {
      attributes: {
        location_state = '',
        location_city = '',
        location_contact_details: { location_office_hours = '', location_access_hours = '' } = {},
      } = {},
    } = locationDetails && locationDetails[0] ? locationDetails[0] : {};
    const { facility_logo } = facilityDetails;
    const facilityLogoUrl = facility_logo?.data?.attributes?.url || '';
    defaultFooterDataCopy.labelsAndLogos = {
      quickLinksLabel: strapiFooterConfig.quick_links_label,
      reachUsLabel: strapiFooterConfig.reach_us_label,
      poweredByText: strapiFooterConfig.powered_by_label,
      copyrightlogo: footerConfig.labelsAndLogos.copyrightlogo || 'FaCopyright',
      facilityLogo: facilityLogoUrl,
    };

    if (location_city && location_state) {
      defaultFooterDataCopy.buttonsConfig = transformedFooterButtonConfig(
        strapiFooterConfig,
        location_city,
        location_state,
        locationDetails,
      );
    }
    if (location_office_hours && location_access_hours) {
      defaultFooterDataCopy.hours = {
        officeHrs: getTransformedHours(location_office_hours),
        gateHrs: getTransformedHours(location_access_hours),
      };
    }
    if (location_office_hours && location_access_hours) {
      defaultFooterDataCopy.show = false || footerConfig.show;
      exampleLocations[0].hours = {
        officeHrs: getTransformedHours(location_office_hours),
        gateHrs: getTransformedHours(location_access_hours),
      };
    }
    defaultFooterDataCopy.curlocations = exampleLocations;
    defaultFooterDataCopy.isHoursForAllLocationsSame =
      true || footerConfig.isHoursForAllLocationsSame;
    defaultFooterDataCopy.iconsConfig = {
      phoneNumberIcon: 'FaPhoneAlt',
      emailIDIcon: 'FaEnvelope',
      facilityAddressIcon: 'FaAddressCard',
      mailingAddressIcon: 'FaMailBulk',
    };
    defaultFooterDataCopy.termsPrivacySiteMapAndDisclaimer =
      generateTermsAndPrivacyDisclaimer(other_links);
    defaultFooterDataCopy.navLinks = generateNavLinks(locationDetails, facilityDetails, 'footer');
    return defaultFooterDataCopy;
  }

  return footerData;
};
