import { Link } from 'gatsby';
import { FooterTypes } from '@codeparva/gatsby-theme-layout-components';
import { exampleLocations } from '../locationMap/config';

export const footerConfig: FooterTypes.IFooterConfig = {
  theme: 'dark',
  variant: 'new-footer-1',
  locationType: 'single', // single or multi
  labelsAndLogos: {
    quickLinksLabel: 'Quick Links',
    reachUsLabel: 'Reach Us',
    poweredByText: 'Powered by',
    copyrightlogo: 'FaCopyright',
    facilityLogo: '',
  },
  show: false,
  curlocations: exampleLocations,
  isHoursForAllLocationsSame: true,
  locations: {
    location: {
      id: '1',
      socialIcons: [
        // {
        //   iconName: 'FaFacebook',
        //   socialIcon: 'tfw-facebook-2-cp',
        //   iconsUrl: 'https://www.facebook.com/catskillselfstorage',
        // },
        // {
        //   iconName: 'FaTwitter',
        //   socialIcon: 'tfw-twitter-2-cp',
        //   iconsUrl: 'https://twitter.com/CatskillSS',
        // },
      ],
      contactDetails: {
        syrasoftEmailID: 'syrasoftsoftware@gmail.com',
        mailingAddress: '790 Mullet Rd, Cape Canaveral FL 32920',
        emailID: 'dockmaster@capemarina.com',
        phoneNumber: '321-783-8410',
        facilityDetails: {
          facilityAddress: '790 Mullet Rd, Cape Canaveral FL 32920',
          googleMapsUrl: 'https://goo.gl/maps/M3q1BkM2gybTPvJt7',
          facilityName: 'Marina Mini Storage',
          facilityLogo: {
            logo: {
              lightTheme: '',
              darkTheme: '',
            },
            renderAs: 'figure',
            altText: 'a logo provided',
          },
        },
      },
    },
  },

  iconsConfig: {
    phoneNumberIcon: 'FaPhoneAlt',
    emailIDIcon: 'FaMailBulk',
    facilityAddressIcon: 'FaAddressCard',
    mailingAddressIcon: 'FaMailBulk',
  },
  navLinks: [
    {
      NavLinkText: 'Home',
      isNavLinkDropDown: false,
      isGrouped: false,
      linkTo: '/',
      RenderAs: Link,
      newTab: true,
      navLinkDropDown: [],
    },
  ],
  termsPrivacySiteMapAndDisclaimer: [
    {
      linkName: 'Terms & Conditions',
      linkUrl: '/termsandconditions',
      newTab: true,
    },
    {
      linkName: 'Privacy Policy',
      linkUrl: '/privacypolicy',
      newTab: true,
    },
    {
      linkName: 'SiteMap',
      linkUrl: '/sitemap.xml',
      newTab: true,
    },
    {
      linkName: 'Disclaimer',
      linkUrl: '/disclaimer',
      newTab: true,
    },
  ],
  buttonsConfig: [],
  hours: undefined,
};
